// COMPONENTS
import React from 'react'
// CUSTOM TEMPLATES
import LandingPage from 'templates/LandingPage'
// CONTENT
import content from 'content/lp/bearblogr-pro-ads.yml'

// bearblogr - PRO version advertising
const bearblogrProAdsLP = () => {
  return <LandingPage content={content} />
}
export default bearblogrProAdsLP
